// це центрує весь додаток, а не тільки форму
//#root {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   margin: 0;
// }

.login-form {
  width: 300px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.login-form h2 {
  margin-top: 0;
}

.login-form form {
  display: flex;
  flex-direction: column;
}

.login-form .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.login-form label {
  margin-bottom: 5px;
}

.login-form input {
  padding: 5px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

.login-button {
  max-width: 100%;
  font-size: 16px;
  font-weight: bold;
  background-color: #2f94dc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.close-button::before,
.close-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #4b4b4b;
}

.close-button::before {
  transform: rotate(45deg);
}

.close-button::after {
  transform: rotate(-45deg);
}

.close-button:hover::before,
.close-button:hover::after {
  height: 3px;
}

.register-link {
  color: #2f94dc;
}
