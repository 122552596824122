:root {
  --ds-shadow-overlay: 0px 8px 12px #091e4226, 0px 0px 1px #091e424f;
  --ds-border: #091e4224;
  --modal-blue: #2f94dc;
}

.modal-actions {
  position: relative;
  margin: 0 auto;
  padding: 10px;
  border-radius: 3px;
  width: 350px;
  height: 330px;
  cursor: move;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: var(
    --ds-shadow-overlay,
    0 8px 16px -4px #091e4240,
    0 0 0 1px var(--ds-border, #091e4214)
  );
}

.modal-actions-body {
  position: relative;
  display: inline-block;
  width: -webkit-fill-available;

  textarea {
    width: -webkit-fill-available;
    margin-top: 8px;
  }
}

.modal-actions-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
  color: grey;
  cursor: pointer;
}

.modal-actions-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-actions-textarea {
  width: 100%;
  height: 100px;
  resize: none;
  border: 1px solid grey;
  padding: 5px;
  margin-bottom: 10px;
}

.modal-actions-select {
  width: 100%;
  margin-bottom: 10px;
}

.modal-actions-left-select {
  width: 70%;
  margin-right: 10%;
}

.modal-actions-right-select {
  width: 30%;
}

.modal-actions-button {
  margin-left: auto;
  display: block;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.board-select {
  position: absolute;
  width: 88.5%;
  border: 2px solid rgb(199, 199, 199);
  box-shadow: var(--ds-shadow-overlay);
  overflow: hidden;
  z-index: 1;
}

.board-select option {
  font-size: 18px;
  padding: 2px;
}

option.board-select:focus {
  background-color: #6585c9 !important;
  color: #fff;
}

.center-draggable {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy-to-inner {
  cursor: auto;
  background-color: #e0e0e0;
  margin-top: 8px;
}

.copy-to-inner span {
  display: block;
  position: relative;
}

.two-selects {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.copy-to-list {
  margin-right: 8px;
  position: relative;
}

.copy-to-card {
  position: relative;
}

.list-select {
  position: absolute;
  top: 48px;
  width: 100%;
  border: 2px solid rgb(199, 199, 199);
  box-shadow: var(--ds-shadow-overlay);
  overflow: hidden;
  z-index: 1;
  -webkit-appearance: none;
}

.list-select option {
  font-size: 18px;
  padding: 2px;
}

.card-select {
  position: absolute;
  //  top:48px;
  width: 100%;
  border: 2px solid rgb(199, 199, 199);
  box-shadow: var(--ds-shadow-overlay);
  overflow: hidden;
  z-index: 1;
  -webkit-appearance: none;
}

.card-select option {
  font-size: 18px;
  padding: 2px;
}

.copy-to-inner-span-board {
  padding: 3px;
}

.copy-to-inner-span-list {
  padding: 3px;
}

.copy-to-inner-span-card {
  padding: 3px;
}
