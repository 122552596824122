.password-strength-meter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.strength-bar {
  flex-grow: 1;
  height: 10px;
}

.strength-bar:not(:last-child) {
  margin-right: 5px;
}

.strength-bar.very-weak {
  background-color: red;
}

.strength-bar.weak {
  background-color: orange;
}

.strength-bar.fair {
  background-color: yellow;
}

.strength-bar.strong {
  background-color: green;
}

.strength-label {
  color: black;
  font-weight: bold;
}

.strength-label.very-weak {
  color: red;
}

.strength-label.weak {
  color: orange;
}

.strength-label.fair {
  color: yellow;
}

.strength-label.strong {
  color: green;
}
