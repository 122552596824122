.modalDiv {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: rgba(91, 112, 131, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #f9f9f9;
  border-radius: 5px;
}

.my-component {
  position: relative;
  width: 700px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.my-component h1 {
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  color: #000;
}

.my-component h2 {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
}

.my-component .main-content {
  display: flex;
  flex-wrap: wrap;
}

.my-component .left-content {
  width: 75%;
}

.my-component .right-content {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-component .left-content h3 {
  font-size: 20px;
  font-weight: bold;
}

.my-component .left-content .avatars {
  display: flex;
  // flex-wrap: wrap;
  justify-content: flex-start;
}

.my-component .left-content .avatars img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.my-component .left-content button {
  display: block;
  margin: 10px;
  padding: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  background-color: #2f94dc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  max-width: 150px;
}

.my-component .left-content .description {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.my-component .left-content .description h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

button {
  display: block;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  background-color: #2f94dc;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
}

.red-button {
  background-color: #fd3636;
}

.my-component .right-content h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.my-component .right-content button {
  display: block;
  margin-top: 10px;
}

.my-component .close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.my-component .close-button::before,
.my-component .close-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #4b4b4b;
}

.my-component .close-button::before {
  transform: rotate(45deg);
}

.my-component .close-button::after {
  transform: rotate(-45deg);
}

.my-component .close-button:hover::before,
.my-component .close-button:hover::after {
  height: 3px;
}

.participants {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ccc;
  border: 1px solid #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin: 0 10px 0 0;
}

.avatar span {
  text-transform: uppercase;
}

.add-participant {
  width: 50px;
  height: 50px;
  border: 1px solid #aaa;
  border-radius: 50%;
  background-color: #aaa;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.join-button {
  border: none;
  color: #fff;
  padding: 8px;
  border-radius: 5px;
  width: auto;
  max-width: 150px;
}

.textarea-card-edit {
  margin-top: 20px;
  resize: none;
  width: 96%;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  border: #2f94dc 2px solid;
  border-radius: 5px;
}
