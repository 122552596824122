.slot {
  border-radius: 13px;
  min-height: 28px;
  background-color: rgb(244, 244, 244);
  box-shadow: 0 3px 0 rgba(9, 30, 66, 0.2509803922);
  margin: 8px;
}

.single-slot {
  border-radius: 13px;
  min-height: 28px;
  background-color: rgb(244, 244, 244);
  box-shadow: 0 3px 0 rgba(9, 30, 66, 0.2509803922);
  margin: 8px;
}
