.add-board-button {
  display: flex;
  align-items: center;
}

.button-modal-close {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 28px;
}

.add-board-input-container {
  display: flex;
  flex-direction: column;
}

.whole-modal {
  display: flex;
  flex-direction: column;
}

#addBoardInput {
  margin-bottom: 20px;
}

.icon-close-board {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 3px;
  width: auto;
  margin: 0;
  color: #fff;
}
