@font-face {
  font-family: 'icons';
  src: url('./icons.ttf?566xi0') format('truetype'),
    url('./icons.woff?566xi0') format('woff'),
    url('./icons.svg?566xi0#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-']::before,
[class*=' icon-']::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkbox:before {
  content: '\e905';
}
.icon-checked:before {
  content: '\e906';
}
.icon-clock:before {
  content: '\e907';
}
.icon-edit:before {
  content: '\e902';
}
.icon-tables:before {
  content: '\e904';
}
.icon-close:before {
  content: '\e903';
}
.icon-dots-three:before {
  content: '\e900';
}
.icon-plus:before {
  content: '\e901';
}
